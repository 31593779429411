/*@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');*/
@import "npm:@fontsource/dm-sans/latin-italic.css";
@import "npm:@fontsource/dm-sans/latin.css";

html {
	-webkit-text-size-adjust: 100%;
	scroll-behavior: smooth;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

:root {
	--darkestblue: #141516;
	/*var(--darkestblue);*/
	--logo-darkblue: #122b32;
	/*var(--logo-darkblue);*/
	--darkblue1: #044E64;
	/*var(--darkblue1);*/
	--blue1: #006480;
	/*var(--blue1);*/
	--blue2: #047B9B;
	/*var(--blue2);*/
	--blue3: #319BB7;
	/*var(--blue3);*/
	--blue4: #60BCD5;
	/*var(--blue4);*/
	--blue5: #B2DEEA;
	/*var(--blue5);*/
	--akzent1: #E8E880;
	/*var(--akzent1);*/
	--akzent2: #F3F3C0;
	/*var(--akzent2);*/
	color-scheme: light;
}

body {
	font-family: "DM Sans", sans-serif;
	font-size: 100%;
	color: #fff;
	text-decoration: none;
	font-weight: normal;
	word-spacing: normal;
	line-height: 1.2;
	background-color: var(--darkestblue);
	-webkit-print-color-adjust: exact;
	cursor: url(/src/img/cursor.png), auto;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	margin: 0 auto;
	background-color: var(--darkestblue);
	font-size: 1.1rem;
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

/* Code font size / prismjs*/
.codebox {
	font-size: 0.9rem;
	margin-bottom: 0.8rem;
}

.outer {
	overflow-x: hidden;
}

.pt-10 {
	padding-top: 10rem;
}

/*Round Author Image for articles*/

.author-img {
	display: flex;
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.author-img img {
	border-radius: 50%;
	width: 60px;
	height: 60px;
}

.author-img div {
	display: flex;
	margin-left: 1rem;
	flex-direction: column;
	justify-content: space-evenly;
}

.author-img div p {
	padding: 0;
	margin: 0;
}

.author-img div p:not(:first-child) {
	color: rgb(158, 158, 158);
}

.blitz-background {
	width: 100%;
	height: 100%;
	background-image: url('/src/img/blitz-header.webp');
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

}

/*Article images*/
article.content p img {
	margin-left: auto;
	margin-right: auto;
}

article.content div {
	display: flex;
}

article.content div div {
	flex: 50%;
	padding: 25px;
}


.animate-bottom {
	position: relative;
	-webkit-animation-name: animatebottom;
	-webkit-animation-duration: 1s;
	animation-name: animatebottom;
	animation-duration: 1s
}

@-webkit-keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}

	to {
		bottom: 0px;
		opacity: 1
	}
}

@keyframes animatebottom {
	from {
		bottom: -100px;
		opacity: 0
	}

	to {
		bottom: 0;
		opacity: 1
	}
}



h1 {
	font-size: 3rem;
	font-weight: 700;
}

h1 span {
	color: var(--akzent1);
}

li span {
	color: var(--akzent1);
}

p span {
	color: var(--akzent1);
}

h2 {
	font-size: 2.1rem;
	font-weight: 600;
}

h3 {
	font-size: 1.5rem;
	font-weight: 600;
}

h4 {
	margin: 0;
	font-size: 0.8rem;
	font-weight: 400;
}

p:not(:last-child) {
	margin-bottom: 1em;
}

h1:not(:last-child) {
	margin-bottom: 1em;
}

h1:not(:first-child) {
	margin-top: 1em;
}

h2:not(:last-child) {
	margin-bottom: 1em;
}

h2:not(:first-child) {
	margin-top: 1em;
}

h3:not(:last-child) {
	margin-bottom: 1em;
}

h3:not(:first-child) {
	margin-top: 1em;
}

.subtitle {
	font-weight: 400;
	margin-top: -1em !important;
}

.flash {
	margin: 0rem 0 1rem 0;
	background-color: #fff;
	padding: 0.8rem 20% 0.8rem 20%;
	color: var(--darkestblue);
	position: relative;
	width: 90%;
}

.flash::before {
	content: "";
	background-image: url('/src/img/flash-white.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	right: -16.3rem;
	bottom: -1.75rem;
	width: 20rem;
	height: 20rem;
}

.is-5 {
	margin: 2rem 0 1rem 0;
}

hr {
	margin: 2rem 0 1rem 0;
}

.margin_3rem {
	margin-top: 3rem;
}

#logo {
	padding: 1.5rem 2% 1rem 20%;
	position: sticky;
	top: 0;
	left: 0;
	z-index: 2000;
}

.logo {
	background-color: #fff;
	transition: all 0.2s ease;
	color: var(--logo-darkblue);
}

.logo2 {
	background-color: #122b32;
	transition: all 0.2s ease;
	color: #fff;
}


#logo img {
	width: 17rem;
	opacity: 1;
	transition: opacity 0.5s ease;
}

.language {
	width: 5rem;
}

.languagebtn img {
	width: 2rem !important;
	display: inline-block;
	transition: all 0.2s ease;
	margin-top: 1rem;
}

.languagebtn img:hover {
	transform: scale(1.05);
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1rem 2rem;
}

.navigation ul {
	margin: 0 0 0 0;
	list-style-type: none;
	padding: 0;
	display: flex;
}

.navigation li {
	position: relative;
	margin-left: 2rem;
}

.navigation li::before {
	position: absolute;
	top: 0;
	left: 0;
	content: "";
	background-image: inherit;
}

#waswiranbieten,
#ergebnisse,
#ueberuns,
#kontakt,
#freeoffers {
	transform: translateY(-150px);
}


.has-opacity-0 {
	opacity: 0;
}

.has-opacity-1 {
	opacity: 1;
}


.is-hidden {
	display: none;
}

header {
	position: relative;
	margin-bottom: -10rem;
}

.bgimgbox {
	text-align: center;
	background-image: url("/src/img/imgbox.svg");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-attachment: fixed;
	padding: 7rem 10% 6rem 10%;
	margin-bottom: 16rem;
	position: relative;
	z-index: 10;
}


.bgimgbox h2 {
	font-size: 2.9rem;
}

.content {
	position: relative;
	padding: 2rem 20% 4rem 20%;
	color: #fff;
}



.flashcontent {
	margin: 1.5rem 0 10rem 0;
	background-color: #fff;
	padding: 2rem 20% 2rem 20%;
	color: var(--darkestblue);
	position: relative;
	width: 90%;
	min-height: 35rem;
	z-index: 10;
}

.flashcontent::before {
	content: "";
	background-image: url('/src/img/flashcontent.svg');
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	right: -17rem;
	bottom: 0;
	width: 20rem;
	height: 45.5rem;
}


.firstblock {
	opacity: 0;
	transform: translateX(-80px);
	transition: all 1s ease;
}

.block {
	opacity: 0;
	transform: translateX(-80px);
	transition: all 1s ease;
}

.visibleblock {
	opacity: 1;
	transform: translateX(0);
	transition: all 1s ease;
}


.content-bild {
	position: relative;
	padding: 0rem 20%;
	color: #fff;
	margin: -1rem 0 -1.5rem 0;
}

.content-white {
	position: relative;
	padding: 2rem 20%;
	background-color: #fff;
	color: var(--darkestblue);
}

footer {
	position: relative;
	padding: 1rem 20%;
	margin-top: auto;
	font-size: 0.9rem;
	background-color: var(--darkblue1);
}

.icons {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin: 0 0 0.5rem -5.5%;
	text-align: center;
}

.iconbox {
	clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0 100%);
	width: 37.5%;
	margin-right: -6.5%;
	padding: 3rem 3rem 2rem 2.5rem;
	color: #fff !important;
	transition: all 0.2s ease-in-out;
}

.iconbox1 {
	background-color: var(--logo-darkblue);
}

.iconbox2 {
	background-color: var(--darkblue1);
}

.iconbox3 {
	background-color: var(--blue1);
}

.yellow-iconbox {
	clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0 100%);
	width: 37.5%;
	margin-right: -6.5%;
	padding: 3rem 3rem 2rem 2.5rem;
	color: var(--darkestblue) !important;
	transition: all 0.2s ease-in-out;
	background-color: var(--akzent1);
}

.yellow-iconbox:hover {
	background-color: var(--akzent2);
}

.yellow-iconbox:hover img {
	transform: scale(1.1);
}

.yellow-iconbox img {
	height: 7rem;
	margin: auto auto 2rem auto;
	transition: all 0.2s ease-in-out;
}

.iconbox:hover {
	background-color: var(--blue3);
}

.iconbox:hover img {
	transform: scale(1.1);
}


.iconbox img {
	height: 7rem;
	margin: auto auto 2rem auto;
	transition: all 0.2s ease-in-out;
}


.img-box {
	width: 100%;
	height: 35rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: -16rem 0 -2rem 0;
	position: relative;
}

.img-box::after {
	position: absolute;
	bottom: 0;
	right: 0;
	content: "";
	width: 10%;
	height: 6rem;
	background-color: var(--darkestblue);
}

.img-box2 {
	width: 100%;
	height: 35rem;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	margin: -16rem 0 -2rem 0;
	position: relative;
}

.img-box2::after {
	position: absolute;
	bottom: 0;
	right: 0;
	content: "";
	width: 10%;
	height: 6rem;
	background-color: var(--darkestblue);
}


.text-box {
	width: 50%;
	padding: 3rem;
}

.img1 {
	background-image: url('/src/img/hacker.webp');
}

.img2 {
	background-image: url('/src/img/monitor-on-table.webp');
}

.img3 {
	background-image: url('/src/img/laptop.webp');
}

.img4 {
	background-image: url('/src/img/handy_onlineshop.webp');
}


/*--Flex--*/

.bgbild {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


.flexbutton {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	/*align-items: flex-start;*/
	flex-direction: row;
	flex-wrap: wrap;
}

.leftbutton {
	width: 50%;
}

.rightbutton {
	width: 50%;
	text-align: right;
}

.flex {
	display: flex;
	display: -ms-flexbox;
	display: -webkit-flex;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	/*align-items: flex-start;*/
	flex-direction: row;
	flex-wrap: wrap;
}

.left {
	width: 60%;
	padding: 2rem 2% 3rem 20%;
}

.right {
	width: 40%;
}

footer .left {
	width: 50%;
	padding: 0rem 0% 0rem 0%;
}

footer .right {
	width: 50%;
	text-align: right;
}

footer .right img {
	width: 2.5rem;
	display: inline-block;
}


/*--Links--*/
a:link,
a:visited,
a:active {
	text-decoration: none;
	color: var(--logo-darkblue);
}

a:hover {
	text-decoration: none;
	color: var(--akzent1);
	cursor: url(/src/img/pointer.png), pointer;
}

footer a:link,
footer a:visited,
footer a:active {
	text-decoration: none;
	color: #fff;
}

footer a:hover {
	text-decoration: none;
	color: var(--akzent1);
	cursor: url(/src/img/pointer.png), pointer;
}

footer a img {
	opacity: 1;
	transition: all 0.2s ease-in-out;
}

footer a img:hover {
	opacity: 0.8;
}

.content a:link,
.content a:visited,
.content a:active {
	text-decoration: none;
	color: var(--akzent1);
}

.content a:hover {
	text-decoration: none;
	color: #fff;
	cursor: url(/src/img/pointer.png), pointer;
}

.logo a:link,
.logo a:visited,
.logo a:active {
	text-decoration: none;
	color: var(--logo-darkblue);
	font-weight: 600;
	text-decoration: none;
	font-size: 1.1rem;
	transition: all 0.1s ease;
}

.logo a:hover {
	text-decoration: none;
	color: var(--akzent1);
	font-weight: 600;
	cursor: url(/src/img/pointer.png), pointer;
}

.logo2 a:link,
.logo2 a:visited,
.logo2 a:active {
	text-decoration: none;
	color: #fff;
	font-weight: 600;
	text-decoration: none;
	font-size: 1.1rem;
	transition: all 0.1s ease;
}

.logo2 a:hover {
	text-decoration: none;
	color: var(--akzent1);
	font-weight: 600;
	cursor: url(/src/img/cursor.png), pointer;
}

/*--Listen--*/
ul {
	margin: 2rem 0 2rem 0;
	list-style-type: none;
	counter-reset: step-counter;
}

ol {
	margin: 2rem 0 2rem 0;
	list-style-type: none;
	counter-reset: step-counter;
}

li {
	position: relative;
	margin: 1rem auto auto auto;
	padding: 0 0 0 1.8rem;
}

li:last-child {
	padding-bottom: 0;
}

ul li::before {
	position: absolute;
	top: 0.4rem;
	left: 0;
	content: "";
	background-image: url('/src/img/bp.svg');
	width: 0.8rem;
	height: 0.8rem;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

ol li::before {
	position: absolute;
	top: 0;
	left: 0;
	text-align: right;
	font-weight: bold;
	content: counter(step-counter)'.';
	counter-increment: step-counter;
	width: 0.8rem;
	height: 0.8rem;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}


/*--Eingrückte Listenpunkte--*/
li ul {
	margin: 0 0 0 0;
	padding: 0 0 0 1rem;
}

/*--Button--*/
.button_box {
	text-align: center;
}

.button_box::after {
	clear: both;
	content: "";
	display: block;
}

.button a {
	display: inline-block;
	background: var(--akzent1);
	padding: 0.6rem 2rem 0.6rem 2rem;
	margin: 2rem auto;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--akzent1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--logo-darkblue) !important;
	font-weight: 600;
	font-size: 1.1rem;
}

.button a:hover {
	background-color: transparent;
	text-decoration: none;
	color: var(--akzent1);
	border: 0.2rem solid var(--akzent1);
}

.kontaktbutton a {
	position: relative;
	display: inline-block;
	background: var(--akzent1);
	padding: 0.6rem 2rem 0.6rem 3.2rem;
	margin: auto auto auto auto;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--akzent1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--logo-darkblue) !important;
	font-weight: 600;
	font-size: 1.1rem;
}

.kontaktbutton a:hover {
	background-color: transparent;
	text-decoration: none;
	color: var(--akzent1) !important;
	border: 0.2rem solid var(--akzent1);
}

.discoveryscan {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 2500;
	pointer-events: none;
}

.discoveryscanbox {
	background-color: var(--akzent1);
	padding: 0.8rem 2rem 0.8rem 6rem;
	position: relative;
	color: var(--darkestblue);
	font-weight: 600;
	text-align: center;
	transition: all 0.3s ease;
	transform: translateX(19.5rem);
	width: 400px;
	pointer-events: auto;
}

.signetscan {
	position: absolute;
	left: 0.4rem;
	top: 1.4rem;
	width: 3.5rem;
	height: 3.5rem;
	background-image: url('../img/signetrasotec.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
}

.discoveryscanbox:hover {
	transform: translateX(1rem);
}

.iconds {
	position: absolute;
	left: 1rem;
	top: 1rem;
	width: 3.5rem;
	height: 3.5rem;
	background-image: url('../img/scan.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	animation: zickzack 5s infinite ease-in-out;
}

@keyframes zickzack {
	0% {
		transform: translate(0, 0);
	}

	25% {
		transform: translate(-0.5rem, 0.5rem);
	}

	50% {
		transform: translate(0.5rem, 1rem);
	}

	75% {
		transform: translate(-1rem, 0.5rem);
	}

	100% {
		transform: translate(0, 0);
	}
}

.discoveryscanbox::before {
	position: absolute;
	content: "";
	left: -4.9rem;
	bottom: 0;
	width: 5rem;
	height: 100%;
	background-image: url('../img/scanbox-before.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}

.discoveryscanbox .button a {
	position: relative;
	display: inline-block;
	background: var(--darkblue1);
	padding: 0.6rem 2rem 0.6rem 2rem;
	margin: 0;
	margin-top: -1rem;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--darkblue1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--akzent1) !important;
	font-weight: 600;
	font-size: 1.1rem;
}

.discoveryscanbox .button a:hover {
	background-color: transparent;
	text-decoration: none;
	color: var(--darkblue1) !important;
	border: 0.2rem solid var(--darkblue1);
}


.mail a::before {
	position: absolute;
	left: 1rem;
	top: 0.7rem;
	content: "";
	background-image: url('/src/img/mailicon1.svg');
	background-repeat: no-repeat;
	height: 1.5rem;
	width: 1.5rem;
}

.telefon a::before {
	position: absolute;
	left: 1rem;
	top: 0.5rem;
	content: "";
	background-image: url('/src/img/telefonicon1.svg');
	background-repeat: no-repeat;
	height: 1.5rem;
	width: 1.5rem;
}

.mail a:hover::before {
	background-image: url('/src/img/mailicon-yellow.svg');
}

.telefon a:hover::before {
	background-image: url('/src/img/telefonicon-yellow.svg');
}


.weiterbutton {
	display: inline-block;
	background: var(--akzent1);
	padding: 0.6rem 2rem 0.6rem 2rem;
	margin: 2rem auto;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--akzent1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--logo-darkblue);
	font-weight: 600;
	font-size: 1.1rem;
}

.weiterbutton:hover {
	background-color: transparent;
	text-decoration: none;
	color: var(--akzent1);
	border: 0.2rem solid var(--akzent1);
}

.greybutton {
	display: inline-block;
	background: var(--akzent1);
	padding: 0.6rem 2rem 0.6rem 2rem;
	margin: 2rem auto;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--akzent1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--logo-darkblue);
	font-weight: 600;
	font-size: 1.1rem;
	filter: grayscale();
}

.greybutton:hover {
	background: var(--akzent1);
	text-decoration: none;
	color: var(--logo-darkblue);
	border: 0.2rem solid var(--akzent1);
}



/*formular*/
/*
input {
	cursor: url(/src/img/cursor.png), pointer;
}

label {
	cursor: url(/src/img/cursor.png), pointer;
}*/

select:focus {
	border: 0.2rem solid var(--logo-darkblue);
}

input[type="text"] {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	width: 20rem;
}

input[type="text"]:focus {
	border: 0.2rem solid var(--logo-darkblue);
}

input[type="datetime-local"] {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	width: 20rem;
}

input[type="datetime-local"]:focus {
	border: 0.2rem solid var(--logo-darkblue);
}

textarea {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	width: 100%;
	height: 7rem;
	resize: none;
}

textarea:focus {
	border: 0.2rem solid var(--logo-darkblue);
}

input[type="email"] {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	width: 20rem;
}

input[type="email"]:focus {
	border: 0.2rem solid var(--logo-darkblue);
}


input[type="tel"] {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	width: 20rem;
}


input[type="tel"]:focus {
	border: 0.2rem solid var(--logo-darkblue);
}

input[type="submit"] {
	display: inline-block;
	background: var(--akzent1);
	padding: 0.6rem 2rem 0.6rem 2rem;
	margin: 2rem auto;
	cursor: url(/src/img/pointer.png), pointer;
	-webkit-border-radius: 5rem;
	border-radius: 5rem;
	border: 0.2rem solid var(--akzent1);
	transition: all 140ms ease 0s;
	-moz-transition: all 140ms ease 0s;
	-webkit-transition: all 140ms ease 0s;
	color: var(--logo-darkblue);
	font-weight: 600;
	font-size: 1.1rem;
	font-family: "DM Sans", sans-serif;
}

input[type="submit"]:hover {
	background-color: transparent;
	text-decoration: none;
	color: var(--akzent1);
	border: 0.2rem solid var(--akzent1);
}

select {
	border-radius: 5px;
	border: 0.2rem solid var(--akzent1);
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	font-size: 0.9rem;
	background-color: var(--akzent1);
	/*cursor: url(/src/img/cursor.png), pointer;*/
}

select option {
	border-radius: 5px;
	border: 0.2rem solid #A4A4A4;
	padding: 8px;
	margin-bottom: 1rem;
	margin-top: 0.5rem;
	outline: none;
	/*cursor: url(/src/img/cursor.png), pointer;*/
}

select:focus {
	border: 0.2rem solid var(--logo-darkblue);
	/*cursor: url(/src/img/cursor.png), pointer;*/
}


[type="radio"] {
	position: relative;
	left: 15px;
	top: -4px;
	z-index: 0;
	-webkit-appearance: none;
	margin: 1rem 0;
}

[type="radio"]+label {
	position: absolute;
}

[type="radio"]+label::before {
	width: 20px;
	height: 20px;
	border-radius: 20px;
	border: 2px solid var(--akzent1);
	background-color: #fff;
	display: block;
	content: "";
	float: left;
	margin-right: 0.5rem;
	z-index: 5;
	position: relative;
}

[type="radio"]:checked+label::before {
	box-shadow: inset 0px 0px 0px 3px #fff;
	background-color: var(--akzent1);
	border: 2px solid var(--akzent1);
}


.displaynone {
	display: none;
}


.displayflex {
	display: flex;
}

.popup-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	justify-content: center;
	align-items: center;
	z-index: 3000;
}

.popup {
	background-color: var(--logo-darkblue);
	color: #fff;
	padding: 2rem 2rem 0 2rem;
	border-radius: 10px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
	width: 50%;
	text-align: center;
}

.popup h2 {
	margin: 0 0 2rem 0;
}



/*--Slider--*/

.slider-wrap {
	width: 100%;
	width: auto;
	height: auto;
	position: relative;
	margin: 0;
	overflow: hidden;
}

.slider-wrap img {
	display: block;
}

.slideshow {
	position: absolute;
	width: 100%;
	top: 0px;
	left: 0px;
	z-index: 0;
}

.slideshow li span {
	width: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	color: transparent;
	opacity: 0;
	z-index: 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-animation: image 24s linear infinite;
	animation: image 24s linear infinite;
}

@keyframes image {
	0% {
		opacity: 0;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	5% {
		opacity: 1;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	25% {
		opacity: 1;
	}

	30% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes image {
	0% {
		opacity: 0;
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	5% {
		opacity: 1;
		-webkit-animation-timing-function: ease-out;
		animation-timing-function: ease-out;
	}

	25% {
		opacity: 1;
	}

	30% {
		opacity: 0;
	}

	100% {
		opacity: 0;
	}
}

.slideshow li {
	margin: 0;
	padding: 0;
	list-style: none;
}

.slideshow li:nth-child(1) span {}

.slideshow li:nth-child(2) span {
	-webkit-animation-delay: 6s;
	animation-delay: 6s;
}

.slideshow li:nth-child(3) span {
	-webkit-animation-delay: 12s;
	animation-delay: 12s;
}

.slideshow li:nth-child(4) span {
	-webkit-animation-delay: 18s;
	animation-delay: 18s;
}

ul.slideshow {
	margin: 0;
	padding-left: 0;
}

.slideshow li::before {
	display: none;
}

/*--Angaben Bilder, Video, etc.--*/

iframe {
	display: block;
	width: 100%;
}

.video-wrapper iframe,
.video-wrapper object,
.video-wrapper embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
	width: auto;
	border: 0;
}

.imgcenter {
	display: inline-block;
}

strong {
	font-weight: 700;
}

.hide_m {}

.hide {
	display: none;
}

.anchor {
	z-index: 1000;
	padding-top: 120px;
	margin-top: -120px;
	max-height: 0px;
}

.socialmedia {
	margin-right: 0.4rem;
}


.icontests {
	width: 100%;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding: 2rem 20%;
}

.icontests::before {
	background-color: hsla(193, 47%, 13%, 0.8);
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}

.icontests img {
	width: 10%;
	z-index: 10;
	position: absolute;
	left: 20%;
	bottom: 4rem;
}

.listboxes {
	display: flex;
	flex-direction: column;
}

.listbox {
	clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0 100%);
	padding: 2rem 3rem 2rem 3rem;
	margin: 0.5rem 0 0 0;
	color: white !important;
	background-color: var(--darkblue1);
}

.listbox:hover {
	background-color: var(--blue3);
}