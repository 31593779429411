@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: url("dm-sans-latin-100-italic.b55542b8.woff2") format("woff2"), url("dm-sans-latin-100-italic.b44e4a4e.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: url("dm-sans-latin-200-italic.ee951483.woff2") format("woff2"), url("dm-sans-latin-200-italic.cf0dde4f.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: url("dm-sans-latin-300-italic.96c0a9ec.woff2") format("woff2"), url("dm-sans-latin-300-italic.6a4e26b4.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url("dm-sans-latin-400-italic.c8357081.woff2") format("woff2"), url("dm-sans-latin-400-italic.0b21c0cb.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: url("dm-sans-latin-500-italic.cd1823ec.woff2") format("woff2"), url("dm-sans-latin-500-italic.bfd25f8e.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: url("dm-sans-latin-600-italic.b91ebfc1.woff2") format("woff2"), url("dm-sans-latin-600-italic.2f02acbf.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: url("dm-sans-latin-700-italic.8d6e78a7.woff2") format("woff2"), url("dm-sans-latin-700-italic.e391ed1f.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: url("dm-sans-latin-800-italic.fcba5bd9.woff2") format("woff2"), url("dm-sans-latin-800-italic.25df43d5.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: url("dm-sans-latin-900-italic.23547492.woff2") format("woff2"), url("dm-sans-latin-900-italic.5736e4c2.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: url("dm-sans-latin-100-normal.8daed485.woff2") format("woff2"), url("dm-sans-latin-100-normal.47735b7e.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: url("dm-sans-latin-200-normal.2f344f62.woff2") format("woff2"), url("dm-sans-latin-200-normal.3d528a08.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("dm-sans-latin-300-normal.d162489f.woff2") format("woff2"), url("dm-sans-latin-300-normal.c0bfc3a3.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("dm-sans-latin-400-normal.e8a9178e.woff2") format("woff2"), url("dm-sans-latin-400-normal.ea1e44e6.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("dm-sans-latin-500-normal.512a1924.woff2") format("woff2"), url("dm-sans-latin-500-normal.9d09dccd.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("dm-sans-latin-600-normal.ec8b04eb.woff2") format("woff2"), url("dm-sans-latin-600-normal.d8e465ce.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("dm-sans-latin-700-normal.2e110d35.woff2") format("woff2"), url("dm-sans-latin-700-normal.576ac82b.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("dm-sans-latin-800-normal.d33283cd.woff2") format("woff2"), url("dm-sans-latin-800-normal.4c1703bf.woff") format("woff");
}

@font-face {
  font-family: DM Sans;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: url("dm-sans-latin-900-normal.359fde5e.woff2") format("woff2"), url("dm-sans-latin-900-normal.cdcaa6f7.woff") format("woff");
}

html {
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --darkestblue: #141516;
  --logo-darkblue: #122b32;
  --darkblue1: #044e64;
  --blue1: #006480;
  --blue2: #047b9b;
  --blue3: #319bb7;
  --blue4: #60bcd5;
  --blue5: #b2deea;
  --akzent1: #e8e880;
  --akzent2: #f3f3c0;
  color-scheme: light;
}

body {
  color: #fff;
  word-spacing: normal;
  background-color: var(--darkestblue);
  -webkit-print-color-adjust: exact;
  cursor: url("cursor.9e4ed285.png"), auto;
  flex-direction: column;
  min-height: 100vh;
  font-family: DM Sans, sans-serif;
  font-size: 100%;
  font-weight: normal;
  line-height: 1.2;
  text-decoration: none;
  display: flex;
}

main {
  background-color: var(--darkestblue);
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 1.1rem;
  display: flex;
}

.codebox {
  margin-bottom: .8rem;
  font-size: .9rem;
}

.outer {
  overflow-x: hidden;
}

.pt-10 {
  padding-top: 10rem;
}

.author-img {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
}

.author-img img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.author-img div {
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1rem;
  display: flex;
}

.author-img div p {
  margin: 0;
  padding: 0;
}

.author-img div p:not(:first-child) {
  color: #9e9e9e;
}

.blitz-background {
  background-image: url("blitz-header.9b3cfc1d.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

article.content p img {
  margin-left: auto;
  margin-right: auto;
}

article.content div {
  display: flex;
}

article.content div div {
  flex: 50%;
  padding: 25px;
}

.animate-bottom {
  animation-name: animatebottom;
  animation-duration: 1s;
  position: relative;
}

@keyframes animatebottom {
  from {
    opacity: 0;
    bottom: -100px;
  }

  to {
    opacity: 1;
    bottom: 0;
  }
}

h1 {
  font-size: 3rem;
  font-weight: 700;
}

h1 span, li span, p span {
  color: var(--akzent1);
}

h2 {
  font-size: 2.1rem;
  font-weight: 600;
}

h3 {
  font-size: 1.5rem;
  font-weight: 600;
}

h4 {
  margin: 0;
  font-size: .8rem;
  font-weight: 400;
}

p:not(:last-child), h1:not(:last-child) {
  margin-bottom: 1em;
}

h1:not(:first-child) {
  margin-top: 1em;
}

h2:not(:last-child) {
  margin-bottom: 1em;
}

h2:not(:first-child) {
  margin-top: 1em;
}

h3:not(:last-child) {
  margin-bottom: 1em;
}

h3:not(:first-child) {
  margin-top: 1em;
}

.subtitle {
  font-weight: 400;
  margin-top: -1em !important;
}

.flash {
  color: var(--darkestblue);
  background-color: #fff;
  width: 90%;
  margin: 0 0 1rem;
  padding: .8rem 20%;
  position: relative;
}

.flash:before {
  content: "";
  background-image: url("flash-white.97a7858e.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20rem;
  height: 20rem;
  position: absolute;
  bottom: -1.75rem;
  right: -16.3rem;
}

.is-5, hr {
  margin: 2rem 0 1rem;
}

.margin_3rem {
  margin-top: 3rem;
}

#logo {
  z-index: 2000;
  padding: 1.5rem 2% 1rem 20%;
  position: sticky;
  top: 0;
  left: 0;
}

.logo {
  color: var(--logo-darkblue);
  background-color: #fff;
  transition: all .2s;
}

.logo2 {
  color: #fff;
  background-color: #122b32;
  transition: all .2s;
}

#logo img {
  opacity: 1;
  width: 17rem;
  transition: opacity .5s;
}

.language {
  width: 5rem;
}

.languagebtn img {
  margin-top: 1rem;
  transition: all .2s;
  display: inline-block;
  width: 2rem !important;
}

.languagebtn img:hover {
  transform: scale(1.05);
}

.header {
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  display: flex;
}

.navigation ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
}

.navigation li {
  margin-left: 2rem;
  position: relative;
}

.navigation li:before {
  content: "";
  background-image: inherit;
  position: absolute;
  top: 0;
  left: 0;
}

#waswiranbieten, #ergebnisse, #ueberuns, #kontakt, #freeoffers {
  transform: translateY(-150px);
}

.has-opacity-0 {
  opacity: 0;
}

.has-opacity-1 {
  opacity: 1;
}

.is-hidden {
  display: none;
}

header {
  margin-bottom: -10rem;
  position: relative;
}

.bgimgbox {
  text-align: center;
  z-index: 10;
  background-image: url("imgbox.f5ea5644.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  margin-bottom: 16rem;
  padding: 7rem 10% 6rem;
  position: relative;
}

.bgimgbox h2 {
  font-size: 2.9rem;
}

.content {
  color: #fff;
  padding: 2rem 20% 4rem;
  position: relative;
}

.flashcontent {
  color: var(--darkestblue);
  z-index: 10;
  background-color: #fff;
  width: 90%;
  min-height: 35rem;
  margin: 1.5rem 0 10rem;
  padding: 2rem 20%;
  position: relative;
}

.flashcontent:before {
  content: "";
  background-image: url("flashcontent.45ba632c.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20rem;
  height: 45.5rem;
  position: absolute;
  bottom: 0;
  right: -17rem;
}

.firstblock, .block {
  opacity: 0;
  transition: all 1s;
  transform: translateX(-80px);
}

.visibleblock {
  opacity: 1;
  transition: all 1s;
  transform: translateX(0);
}

.content-bild {
  color: #fff;
  margin: -1rem 0 -1.5rem;
  padding: 0 20%;
  position: relative;
}

.content-white {
  color: var(--darkestblue);
  background-color: #fff;
  padding: 2rem 20%;
  position: relative;
}

footer {
  background-color: var(--darkblue1);
  margin-top: auto;
  padding: 1rem 20%;
  font-size: .9rem;
  position: relative;
}

.icons {
  text-align: center;
  flex-flow: wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 .5rem -5.5%;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.iconbox {
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0 100%);
  width: 37.5%;
  margin-right: -6.5%;
  padding: 3rem 3rem 2rem 2.5rem;
  transition: all .2s ease-in-out;
  color: #fff !important;
}

.iconbox1 {
  background-color: var(--logo-darkblue);
}

.iconbox2 {
  background-color: var(--darkblue1);
}

.iconbox3 {
  background-color: var(--blue1);
}

.yellow-iconbox {
  clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0 100%);
  background-color: var(--akzent1);
  width: 37.5%;
  margin-right: -6.5%;
  padding: 3rem 3rem 2rem 2.5rem;
  transition: all .2s ease-in-out;
  color: var(--darkestblue) !important;
}

.yellow-iconbox:hover {
  background-color: var(--akzent2);
}

.yellow-iconbox:hover img {
  transform: scale(1.1);
}

.yellow-iconbox img {
  height: 7rem;
  margin: auto auto 2rem;
  transition: all .2s ease-in-out;
}

.iconbox:hover {
  background-color: var(--blue3);
}

.iconbox:hover img {
  transform: scale(1.1);
}

.iconbox img {
  height: 7rem;
  margin: auto auto 2rem;
  transition: all .2s ease-in-out;
}

.img-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 35rem;
  margin: -16rem 0 -2rem;
  position: relative;
}

.img-box:after {
  content: "";
  background-color: var(--darkestblue);
  width: 10%;
  height: 6rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.img-box2 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 35rem;
  margin: -16rem 0 -2rem;
  position: relative;
}

.img-box2:after {
  content: "";
  background-color: var(--darkestblue);
  width: 10%;
  height: 6rem;
  position: absolute;
  bottom: 0;
  right: 0;
}

.text-box {
  width: 50%;
  padding: 3rem;
}

.img1 {
  background-image: url("hacker.d7dd105a.webp");
}

.img2 {
  background-image: url("monitor-on-table.66026580.webp");
}

.img3 {
  background-image: url("laptop.4379bc88.webp");
}

.img4 {
  background-image: url("handy_onlineshop.86ae8b45.webp");
}

.bgbild {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.flexbutton {
  flex-flow: wrap;
  justify-content: space-between;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.leftbutton {
  width: 50%;
}

.rightbutton {
  text-align: right;
  width: 50%;
}

.flex {
  flex-flow: wrap;
  justify-content: space-between;
  display: -ms-flexbox;
  display: -webkit-flex;
}

.left {
  width: 60%;
  padding: 2rem 2% 3rem 20%;
}

.right {
  width: 40%;
}

footer .left {
  width: 50%;
  padding: 0 0%;
}

footer .right {
  text-align: right;
  width: 50%;
}

footer .right img {
  width: 2.5rem;
  display: inline-block;
}

a:link, a:visited, a:active {
  color: var(--logo-darkblue);
  text-decoration: none;
}

a:hover {
  color: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  text-decoration: none;
}

footer a:link, footer a:visited, footer a:active {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  color: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  text-decoration: none;
}

footer a img {
  opacity: 1;
  transition: all .2s ease-in-out;
}

footer a img:hover {
  opacity: .8;
}

.content a:link, .content a:visited, .content a:active {
  color: var(--akzent1);
  text-decoration: none;
}

.content a:hover {
  color: #fff;
  cursor: url("pointer.f357f6e5.png"), pointer;
  text-decoration: none;
}

.logo a:link, .logo a:visited, .logo a:active {
  color: var(--logo-darkblue);
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
}

.logo a:hover {
  color: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  font-weight: 600;
  text-decoration: none;
}

.logo2 a:link, .logo2 a:visited, .logo2 a:active {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .1s;
}

.logo2 a:hover {
  color: var(--akzent1);
  cursor: url("cursor.9e4ed285.png"), pointer;
  font-weight: 600;
  text-decoration: none;
}

ul, ol {
  counter-reset: step-counter;
  margin: 2rem 0;
  list-style-type: none;
}

li {
  margin: 1rem auto auto;
  padding: 0 0 0 1.8rem;
  position: relative;
}

li:last-child {
  padding-bottom: 0;
}

ul li:before {
  content: "";
  background-image: url("bp.80328487.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .8rem;
  height: .8rem;
  position: absolute;
  top: .4rem;
  left: 0;
}

ol li:before {
  text-align: right;
  content: counter(step-counter) ".";
  counter-increment: step-counter;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: .8rem;
  height: .8rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  left: 0;
}

li ul {
  margin: 0;
  padding: 0 0 0 1rem;
}

.button_box {
  text-align: center;
}

.button_box:after {
  clear: both;
  content: "";
  display: block;
}

.button a {
  background: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--akzent1);
  border-radius: 5rem;
  margin: 2rem auto;
  padding: .6rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
  color: var(--logo-darkblue) !important;
}

.button a:hover {
  color: var(--akzent1);
  border: .2rem solid var(--akzent1);
  background-color: #0000;
  text-decoration: none;
}

.kontaktbutton a {
  background: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--akzent1);
  border-radius: 5rem;
  margin: auto;
  padding: .6rem 2rem .6rem 3.2rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
  position: relative;
  color: var(--logo-darkblue) !important;
}

.kontaktbutton a:hover {
  border: .2rem solid var(--akzent1);
  background-color: #0000;
  text-decoration: none;
  color: var(--akzent1) !important;
}

.discoveryscan {
  z-index: 2500;
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
}

.discoveryscanbox {
  background-color: var(--akzent1);
  color: var(--darkestblue);
  text-align: center;
  pointer-events: auto;
  width: 400px;
  padding: .8rem 2rem .8rem 6rem;
  font-weight: 600;
  transition: all .3s;
  position: relative;
  transform: translateX(19.5rem);
}

.signetscan {
  background-image: url("signetrasotec.83678b9a.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.5rem;
  height: 3.5rem;
  position: absolute;
  top: 1.4rem;
  left: .4rem;
}

.discoveryscanbox:hover {
  transform: translateX(1rem);
}

.iconds {
  background-image: url("scan.72ede60d.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 3.5rem;
  height: 3.5rem;
  animation: 5s ease-in-out infinite zickzack;
  position: absolute;
  top: 1rem;
  left: 1rem;
}

@keyframes zickzack {
  0% {
    transform: translate(0);
  }

  25% {
    transform: translate(-.5rem, .5rem);
  }

  50% {
    transform: translate(.5rem, 1rem);
  }

  75% {
    transform: translate(-1rem, .5rem);
  }

  100% {
    transform: translate(0);
  }
}

.discoveryscanbox:before {
  content: "";
  background-image: url("scanbox-before.3eb0e0b3.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 5rem;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: -4.9rem;
}

.discoveryscanbox .button a {
  background: var(--darkblue1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--darkblue1);
  border-radius: 5rem;
  margin: -1rem 0 0;
  padding: .6rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
  position: relative;
  color: var(--akzent1) !important;
}

.discoveryscanbox .button a:hover {
  border: .2rem solid var(--darkblue1);
  background-color: #0000;
  text-decoration: none;
  color: var(--darkblue1) !important;
}

.mail a:before {
  content: "";
  background-image: url("mailicon1.917b207e.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: .7rem;
  left: 1rem;
}

.telefon a:before {
  content: "";
  background-image: url("telefonicon1.9ce582ee.svg");
  background-repeat: no-repeat;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: .5rem;
  left: 1rem;
}

.mail a:hover:before {
  background-image: url("mailicon-yellow.fa65e41b.svg");
}

.telefon a:hover:before {
  background-image: url("telefonicon-yellow.7729160f.svg");
}

.weiterbutton {
  background: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--akzent1);
  color: var(--logo-darkblue);
  border-radius: 5rem;
  margin: 2rem auto;
  padding: .6rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
}

.weiterbutton:hover {
  color: var(--akzent1);
  border: .2rem solid var(--akzent1);
  background-color: #0000;
  text-decoration: none;
}

.greybutton {
  background: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--akzent1);
  color: var(--logo-darkblue);
  filter: grayscale();
  border-radius: 5rem;
  margin: 2rem auto;
  padding: .6rem 2rem;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
}

.greybutton:hover {
  background: var(--akzent1);
  color: var(--logo-darkblue);
  border: .2rem solid var(--akzent1);
  text-decoration: none;
}

input[type="text"] {
  border: .2rem solid var(--akzent1);
  border-radius: 5px;
  outline: none;
  width: 20rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

input[type="text"]:focus {
  border: .2rem solid var(--logo-darkblue);
}

input[type="datetime-local"] {
  border: .2rem solid var(--akzent1);
  border-radius: 5px;
  outline: none;
  width: 20rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

input[type="datetime-local"]:focus {
  border: .2rem solid var(--logo-darkblue);
}

textarea {
  border: .2rem solid var(--akzent1);
  resize: none;
  border-radius: 5px;
  outline: none;
  width: 100%;
  height: 7rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

textarea:focus {
  border: .2rem solid var(--logo-darkblue);
}

input[type="email"] {
  border: .2rem solid var(--akzent1);
  border-radius: 5px;
  outline: none;
  width: 20rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

input[type="email"]:focus {
  border: .2rem solid var(--logo-darkblue);
}

input[type="tel"] {
  border: .2rem solid var(--akzent1);
  border-radius: 5px;
  outline: none;
  width: 20rem;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

input[type="tel"]:focus {
  border: .2rem solid var(--logo-darkblue);
}

input[type="submit"] {
  background: var(--akzent1);
  cursor: url("pointer.f357f6e5.png"), pointer;
  border: .2rem solid var(--akzent1);
  color: var(--logo-darkblue);
  border-radius: 5rem;
  margin: 2rem auto;
  padding: .6rem 2rem;
  font-family: DM Sans, sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all .14s;
  display: inline-block;
}

input[type="submit"]:hover {
  color: var(--akzent1);
  border: .2rem solid var(--akzent1);
  background-color: #0000;
  text-decoration: none;
}

select {
  border: .2rem solid var(--akzent1);
  background-color: var(--akzent1);
  border-radius: 5px;
  outline: none;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
  font-size: .9rem;
}

select option {
  border: .2rem solid #a4a4a4;
  border-radius: 5px;
  outline: none;
  margin-top: .5rem;
  margin-bottom: 1rem;
  padding: 8px;
}

select:focus {
  border: .2rem solid var(--logo-darkblue);
}

[type="radio"] {
  z-index: 0;
  -webkit-appearance: none;
  margin: 1rem 0;
  position: relative;
  top: -4px;
  left: 15px;
}

[type="radio"] + label {
  position: absolute;
}

[type="radio"] + label:before {
  border: 2px solid var(--akzent1);
  content: "";
  float: left;
  z-index: 5;
  background-color: #fff;
  border-radius: 20px;
  width: 20px;
  height: 20px;
  margin-right: .5rem;
  display: block;
  position: relative;
}

[type="radio"]:checked + label:before {
  background-color: var(--akzent1);
  border: 2px solid var(--akzent1);
  box-shadow: inset 0 0 0 3px #fff;
}

.displaynone {
  display: none;
}

.displayflex {
  display: flex;
}

.popup-overlay {
  z-index: 3000;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.popup {
  background-color: var(--logo-darkblue);
  color: #fff;
  text-align: center;
  border-radius: 10px;
  width: 50%;
  padding: 2rem 2rem 0;
  box-shadow: 0 5px 15px #0000004d;
}

.popup h2 {
  margin: 0 0 2rem;
}

.slider-wrap {
  width: auto;
  height: auto;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.slider-wrap img {
  display: block;
}

.slideshow {
  z-index: 0;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.slideshow li span {
  color: #0000;
  opacity: 0;
  z-index: 0;
  backface-visibility: hidden;
  width: 100%;
  animation: 24s linear infinite image;
  position: absolute;
  top: 0;
  left: 0;
}

@keyframes image {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
  }

  5% {
    opacity: 1;
    animation-timing-function: ease-out;
  }

  25% {
    opacity: 1;
  }

  30% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.slideshow li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.slideshow li:nth-child(2) span {
  animation-delay: 6s;
}

.slideshow li:nth-child(3) span {
  animation-delay: 12s;
}

.slideshow li:nth-child(4) span {
  animation-delay: 18s;
}

ul.slideshow {
  margin: 0;
  padding-left: 0;
}

.slideshow li:before {
  display: none;
}

iframe {
  width: 100%;
  display: block;
}

.video-wrapper iframe, .video-wrapper object, .video-wrapper embed {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.video-wrapper {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

img {
  border: 0;
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
}

.imgcenter {
  display: inline-block;
}

strong {
  font-weight: 700;
}

.hide {
  display: none;
}

.anchor {
  z-index: 1000;
  max-height: 0;
  margin-top: -120px;
  padding-top: 120px;
}

.socialmedia {
  margin-right: .4rem;
}

.icontests {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 2rem 20%;
  position: relative;
}

.icontests:before {
  content: "";
  background-color: #122a31cc;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.icontests img {
  z-index: 10;
  width: 10%;
  position: absolute;
  bottom: 4rem;
  left: 20%;
}

.listboxes {
  flex-direction: column;
  display: flex;
}

.listbox {
  clip-path: polygon(3% 0, 100% 0%, 97% 100%, 0 100%);
  background-color: var(--darkblue1);
  margin: .5rem 0 0;
  padding: 2rem 3rem;
  color: #fff !important;
}

.listbox:hover {
  background-color: var(--blue3);
}
/*# sourceMappingURL=index.12fdf21b.css.map */
